import * as React from 'react'

export default () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0c-1.659 0-3.215.313-4.67.94a12.063 12.063 0 0 0-3.806 2.564A12.248 12.248 0 0 0 .951 7.312C.317 8.77 0 10.328 0 11.988c0 1.66.317 3.219.951 4.675a12.248 12.248 0 0 0 2.573 3.809 12.234 12.234 0 0 0 3.805 2.576C8.785 23.683 10.341 24 12 24c1.659 0 3.215-.317 4.67-.952a12.234 12.234 0 0 0 3.806-2.576 12.248 12.248 0 0 0 2.573-3.809c.634-1.456.951-3.015.951-4.675 0-1.66-.317-3.219-.951-4.676a12.248 12.248 0 0 0-2.573-3.808A12.063 12.063 0 0 0 16.67.94C15.215.313 13.659 0 12 0zM9.073 16.968H6.634V9.156h2.44v7.812zM7.83 8.18c-.374 0-.675-.118-.902-.354a1.201 1.201 0 0 1-.342-.867c0-.342.118-.626.354-.854.236-.228.549-.342.94-.342.39 0 .694.114.914.342.22.228.337.512.353.854 0 .342-.118.631-.353.867-.236.236-.557.354-.964.354zm10.098 8.79h-2.415v-4.346c0-.505-.097-.912-.292-1.221-.196-.31-.513-.464-.952-.464-.325 0-.59.098-.792.293a2.042 2.042 0 0 0-.452.61.952.952 0 0 0-.06.281 4.304 4.304 0 0 0-.013.33v4.516h-2.439v-5.322c0-.488-.004-.94-.012-1.355-.008-.415-.02-.794-.037-1.135h2.098l.122 1.074h.049c.162-.26.439-.537.829-.83.39-.293.919-.44 1.585-.44.797 0 1.46.277 1.988.83.529.554.793 1.408.793 2.564v4.614z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
