import * as React from 'react'

export default () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fill-rule="evenodd">
        <circle fill="#8A94A6" cx="12" cy="12" r="12" />
        <g fill-rule="nonzero">
          <path
            d="M8.611 7.49a.72.72 0 0 1-.675-.759l.053-.864a.722.722 0 0 1 .763-.673l.867.054a.717.717 0 0 1 .675.762l-.053.86a.519.519 0 0 1-.02.111l-.03.15-.57.421-1.01-.062z"
            fill="#8A94A6"
          />
          <path
            d="M8.71 4.76c-.61 0-1.118.474-1.156 1.08l-.053.863v.003a1.156 1.156 0 0 0 1.084 1.217l.85.053.317.02.255-.19.309-.227.268-.197.062-.3a.942.942 0 0 0 .03-.184l.053-.861a1.15 1.15 0 0 0-1.084-1.222L8.78 4.76h-.003a1.192 1.192 0 0 0-.069-.002m.648 1.882l.032-.513-.516-.031-.032.513.516.031m-.648-1.014a.33.33 0 0 1 .018 0l.865.054a.283.283 0 0 1 .267.302l-.053.861c0 .013-.006.025-.009.038l-.308.228-.85-.053a.286.286 0 0 1-.269-.3l.054-.863a.285.285 0 0 1 .285-.267M12.281 8.943c-3.624 0-6.573 2.937-6.573 6.547 0 1.24.349 2.402.954 3.392h1.05a5.634 5.634 0 0 1-1.133-3.392c0-3.132 2.558-5.68 5.702-5.68 1.494 0 2.924.623 4.028 1.754 1.064 1.09 1.675 2.52 1.675 3.926 0 .961-.16 2.269-.853 3.392h.994c.48-.962.73-2.112.73-3.392 0-1.63-.7-3.28-1.921-4.531-1.27-1.3-2.922-2.016-4.653-2.016"
            fill="#FFF"
          />
          <path
            d="M11.042 17.264a.588.588 0 0 1-.589.587.588.588 0 1 1 .59-.587M14.699 17.264a.588.588 0 0 1-.59.587.588.588 0 1 1 .59-.587"
            fill="#FFF"
          />
          <path
            d="M11.974 9.738c-.38 0-.757.038-1.127.114-1.13.231-2.18.808-3.034 1.668-1.42 1.429-1.715 2.962-1.777 4.402l3.816-4.031-1.01 3.154c1.899-.988 4.07-1.135 6.47-.433.764.224 1.457.054 1.807-.441.36-.51.268-1.236-.249-1.943a6.045 6.045 0 0 0-2.388-1.944 6.196 6.196 0 0 0-2.508-.546zM5.15 18.124V17.03c0-1.81 0-4.064 2.045-6.121.976-.982 2.178-1.642 3.477-1.908 1.368-.28 2.808-.111 4.164.489a6.929 6.929 0 0 1 2.74 2.226c.735 1.007.833 2.138.257 2.953-.564.797-1.65 1.101-2.765.775-2.574-.753-4.843-.436-6.743.94l-1.075.78.61-1.903-2.71 2.862z"
            fill="#FFF"
          />
          <path
            d="M13.848 9.051a2.142 2.142 0 0 1-2.554 1.276C10.12 9.998 9.501 9.06 9.65 7.867c.148-1.185.952-2.224 1.878-3.505-.76 1.614-.114 1.765.09 1.757.203-.008.498.027.918.165a2.112 2.112 0 0 1 1.312 2.767"
            fill="#8A94A6"
          />
          <path
            d="M10.843 6.092c-.402.627-.684 1.211-.761 1.828-.122.978.375 1.722 1.33 1.99a1.7 1.7 0 0 0 2.03-1.014c.167-.435.15-.905-.05-1.323a1.67 1.67 0 0 0-.993-.877c-.388-.128-.63-.148-.763-.143a.842.842 0 0 1-.793-.461m1.013 4.746c-.226 0-.454-.03-.68-.093-.676-.19-1.217-.565-1.563-1.085-.345-.518-.482-1.156-.396-1.847.152-1.218.907-2.257 1.78-3.459l.178-.246.748.439c-.37.782-.327 1.072-.308 1.138.297-.01.653.054 1.058.188.67.221 1.205.693 1.507 1.329a2.52 2.52 0 0 1 .075 2.005c-.384.997-1.365 1.631-2.4 1.631"
            fill="#FFF"
          />
          <path
            d="M12.747 12.894a.505.505 0 0 1-.391-.185l-.294-.358a.501.501 0 0 1 .051-.688.496.496 0 0 1-.166-.132l-.067-.081a.501.501 0 0 1 .072-.707l.042-.034-.43-.525a.545.545 0 0 1 .426-.891c.165 0 .32.073.424.2l1.831 2.245a.545.545 0 0 1-.425.89.548.548 0 0 1-.38-.151l-.373.304a.508.508 0 0 1-.32.113"
            fill="#8A94A6"
          />
          <path
            d="M12.69 9.268a.903.903 0 0 0-1.272-.127.9.9 0 0 0-.13 1.268l.24.291c-.176.3-.157.69.076.974l.045.055a.855.855 0 0 0 .137.847l.293.358a.86.86 0 0 0 1.213.122l.176-.143a.91.91 0 0 0 .924-.133.9.9 0 0 0 .129-1.267L12.69 9.27l-.001-.002zm-.7.38c.055 0 .11.024.148.07l1.83 2.244a.19.19 0 0 1-.148.311.192.192 0 0 1-.149-.07l-.182-.223-.647.526a.15.15 0 0 1-.21-.021l-.293-.358a.147.147 0 0 1 .02-.207l.65-.529-.265-.326-.322.262a.142.142 0 0 1-.199-.02l-.067-.082a.147.147 0 0 1 .021-.207l.318-.259-.654-.8a.19.19 0 0 1 .149-.311z"
            fill="#FFF"
          />
        </g>
      </g>
    </svg>
  )
}
