import * as React from 'react'

export default () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C8.687 0 5.86 1.172 3.516 3.516 1.172 5.859 0 8.687 0 12c0 3.313 1.172 6.14 3.516 8.484C5.859 22.828 8.687 24 12 24c3.313 0 6.14-1.172 8.484-3.516C22.828 18.141 24 15.313 24 12c0-3.313-1.172-6.14-3.516-8.484C18.141 1.172 15.313 0 12 0zm5.953 9.328c.031.125.047.25.047.375 0 2.125-.766 4.055-2.297 5.79-1.531 1.734-3.578 2.6-6.14 2.6a8.22 8.22 0 0 1-4.5-1.312c.218.032.453.047.703.047 1.343 0 2.562-.422 3.656-1.265a3.065 3.065 0 0 1-1.735-.61c-.5-.375-.843-.86-1.03-1.453a3.416 3.416 0 0 0 1.359-.047 3.091 3.091 0 0 1-1.711-1.031 2.798 2.798 0 0 1-.68-1.875V10.5c.406.25.86.375 1.36.375-.907-.594-1.36-1.406-1.36-2.438 0-.53.14-1.03.422-1.5 1.562 1.938 3.594 2.97 6.094 3.094a2.55 2.55 0 0 1-.094-.703c0-.812.289-1.508.867-2.086A2.844 2.844 0 0 1 15 6.375c.875 0 1.594.312 2.156.938a5.42 5.42 0 0 0 1.875-.704c-.219.72-.64 1.266-1.265 1.641a5.69 5.69 0 0 0 1.687-.469 6.404 6.404 0 0 1-1.5 1.547z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
