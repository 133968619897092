import * as React from 'react'

export default () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor" fillRule="evenodd">
        <path d="M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm3.808-6.206C18.836 17.7 20 15.75 20 15.75c0-4.329-1.973-7.838-1.973-7.838C16.055 6.46 14.178 6.5 14.178 6.5l-.192.215c2.33.7 3.411 1.708 3.411 1.708a11.317 11.317 0 0 0-4.123-1.291 11.808 11.808 0 0 0-2.767.027c-.082 0-.15.013-.233.027-.48.04-1.644.215-3.11.847-.506.228-.808.39-.808.39S7.493 7.36 9.96 6.66l-.137-.16s-1.877-.041-3.85 1.41c0 0-1.972 3.51-1.972 7.84 0 0 1.15 1.949 4.178 2.043 0 0 .507-.605.918-1.116-1.74-.51-2.397-1.586-2.397-1.586s.137.094.383.228a.189.189 0 0 0 .055.04c.041.027.082.04.123.068a8.1 8.1 0 0 0 1 .457c.562.215 1.233.43 2.014.578a9.774 9.774 0 0 0 3.548.013 9.145 9.145 0 0 0 1.986-.578 7.896 7.896 0 0 0 1.576-.793s-.685 1.103-2.48 1.6c.411.511.904 1.09.904 1.09z" />
        <path d="M9.715 11c-.735 0-1.315.62-1.315 1.375 0 .756.593 1.375 1.315 1.375.734 0 1.314-.62 1.314-1.375.013-.756-.58-1.375-1.314-1.375zM14.115 11c-.735 0-1.315.62-1.315 1.375 0 .756.593 1.375 1.315 1.375.734 0 1.314-.62 1.314-1.375 0-.756-.58-1.375-1.314-1.375z" />
      </g>
    </svg>
  )
}
