import * as React from 'react'

export default () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.665 15.63c.143.142.143.28 0 .415-.553.553-1.442.83-2.665.83s-2.112-.277-2.665-.83c-.143-.134-.143-.273 0-.416a.273.273 0 0 1 .2-.08c.081 0 .148.027.202.08.428.438 1.183.657 2.263.657 1.071 0 1.826-.219 2.263-.657a.273.273 0 0 1 .201-.08c.08 0 .148.027.201.08zm-4.111-2.425c0 .33-.116.612-.349.844a1.15 1.15 0 0 1-.843.348c-.33 0-.614-.116-.85-.348a1.138 1.138 0 0 1-.356-.844c0-.339.119-.625.355-.857.237-.232.52-.348.85-.348.331 0 .612.118.844.355.233.237.349.52.349.85zm5.29 0c0 .33-.119.612-.355.844-.237.232-.52.348-.85.348a1.15 1.15 0 0 1-.844-.348 1.15 1.15 0 0 1-.349-.844c0-.33.116-.613.349-.85.232-.237.513-.355.843-.355.33 0 .614.116.85.348.237.232.356.518.356.857zm3.361-1.607c0-.437-.156-.812-.468-1.125a1.55 1.55 0 0 0-1.139-.469c-.446 0-.83.161-1.152.483-1.16-.804-2.549-1.233-4.165-1.286l.844-3.79 2.679.602c0 .33.116.612.348.844.232.232.513.348.844.348.33 0 .613-.118.85-.355a1.16 1.16 0 0 0 .355-.85c0-.33-.118-.614-.355-.85a1.16 1.16 0 0 0-.85-.355c-.483 0-.84.223-1.072.67l-2.96-.657c-.17-.045-.281.027-.335.214l-.924 4.179c-1.607.062-2.986.495-4.138 1.299-.313-.33-.7-.496-1.165-.496a1.55 1.55 0 0 0-1.139.47 1.534 1.534 0 0 0-.468 1.124 1.596 1.596 0 0 0 .91 1.446c-.053.242-.08.492-.08.75 0 1.269.625 2.353 1.875 3.255 1.25.902 2.754 1.353 4.513 1.353 1.768 0 3.277-.451 4.527-1.353 1.25-.902 1.875-1.986 1.875-3.254 0-.286-.031-.54-.094-.764a1.586 1.586 0 0 0 .884-1.433zM24 12a11.73 11.73 0 0 1-.95 4.66c-.635 1.483-1.487 2.76-2.559 3.831-1.071 1.072-2.348 1.924-3.83 2.558A11.73 11.73 0 0 1 12 24a11.73 11.73 0 0 1-4.66-.95c-1.483-.635-2.76-1.487-3.831-2.559-1.072-1.071-1.924-2.348-2.558-3.83A11.73 11.73 0 0 1 0 12c0-1.625.317-3.179.95-4.66.635-1.483 1.487-2.76 2.559-3.831C4.58 2.437 5.857 1.585 7.339.95A11.73 11.73 0 0 1 12 0c1.625 0 3.179.317 4.66.95 1.483.635 2.76 1.487 3.831 2.559 1.072 1.071 1.924 2.348 2.558 3.83A11.73 11.73 0 0 1 24 12z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
