import * as React from 'react'

export default () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <circle fill="currentColor" cx="12" cy="12" r="12" />
        <path
          d="M12.008 6c-.894 0-1.738.17-2.53.51a6.56 6.56 0 0 0-2.073 1.395A6.585 6.585 0 0 0 6.011 9.97a6.288 6.288 0 0 0-.511 2.522c0 .905.17 1.75.51 2.538.34.787.806 1.476 1.395 2.065a6.56 6.56 0 0 0 2.073 1.394c.792.34 1.636.511 2.53.511.894 0 1.737-.17 2.53-.51a6.56 6.56 0 0 0 2.072-1.395 6.585 6.585 0 0 0 1.395-2.065c.34-.788.51-1.633.51-2.538 0-.894-.17-1.734-.51-2.522a6.585 6.585 0 0 0-1.395-2.065 6.56 6.56 0 0 0-2.072-1.394A6.343 6.343 0 0 0 12.008 6zm5.15 5.852h-1.92c-.06-.965-.218-1.79-.472-2.476-.254-.686-.538-1.258-.853-1.715A5.15 5.15 0 0 1 16.1 9.3c.28.36.51.756.693 1.188.183.432.305.887.366 1.364zm-5.166 5.7a5.695 5.695 0 0 1-.518-.526 4.681 4.681 0 0 1-.594-.876 6.696 6.696 0 0 1-.51-1.273 8.303 8.303 0 0 1-.298-1.73h3.856c-.04.661-.137 1.238-.29 1.73a7.032 7.032 0 0 1-.518 1.273 4.456 4.456 0 0 1-.602.876 6.321 6.321 0 0 1-.526.526zm-1.92-5.7c.05-.66.15-1.24.297-1.737.148-.498.32-.925.518-1.28a5.227 5.227 0 0 1 1.105-1.402c.143.122.318.297.526.525.208.229.41.521.602.877.193.355.366.782.518 1.28.153.498.25 1.077.29 1.737h-3.856zm.015-4.19c-.304.456-.584 1.028-.838 1.714-.254.686-.411 1.511-.472 2.476h-1.92c.06-.477.18-.932.358-1.364.177-.432.408-.828.693-1.188.284-.361.61-.681.975-.96.366-.28.767-.506 1.204-.679zm-3.23 5.486h1.92c.06.955.218 1.775.472 2.461.254.686.534 1.257.838 1.715a5.002 5.002 0 0 1-1.204-.679 5.587 5.587 0 0 1-.975-.952 4.813 4.813 0 0 1-.693-1.181 5.343 5.343 0 0 1-.359-1.364zm7.056 4.19c.315-.456.6-1.028.853-1.714.254-.686.412-1.511.473-2.476h1.92a5.153 5.153 0 0 1-.366 1.364A5.318 5.318 0 0 1 16.1 15.7a5.15 5.15 0 0 1-2.187 1.638z"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}
