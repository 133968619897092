import * as React from 'react'

export default () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.484 3.516C18.141 1.172 15.313 0 12 0 8.687 0 5.86 1.172 3.516 3.516 1.172 5.859 0 8.687 0 12c0 3.313 1.172 6.14 3.516 8.484C5.859 22.828 8.687 24 12 24c3.313 0 6.14-1.172 8.484-3.516C22.828 18.141 24 15.313 24 12c0-3.313-1.172-6.14-3.516-8.484zM19.36 14.672c-.03.562-.226 1.094-.586 1.594-.359.5-.789.797-1.289.89-3.343.25-7 .25-10.968 0-.5-.062-.93-.351-1.29-.867-.359-.516-.554-1.055-.585-1.617a31.054 31.054 0 0 1 0-5.39c.03-.532.226-1.055.586-1.571.359-.516.789-.805 1.289-.867 3.25-.25 6.906-.25 10.968 0 .563.031 1.008.281 1.336.75.328.469.508 1 .54 1.593.156 1.813.156 3.641 0 5.485zM10.5 15l4.5-3-4.5-3v6z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
