import * as React from 'react'

export default () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0C8.687 0 5.86 1.172 3.516 3.516 1.172 5.859 0 8.687 0 12c0 3.313 1.172 6.14 3.516 8.484C5.859 22.828 8.687 24 12 24c3.313 0 6.14-1.172 8.484-3.516C22.828 18.141 24 15.313 24 12c0-3.313-1.172-6.14-3.516-8.484C18.141 1.172 15.313 0 12 0zm3.094 12h-1.969v7.219h-2.672V12H8.625V9.516h1.828V8.062c0-1.093.203-1.914.61-2.46.406-.547 1.171-.82 2.296-.82h1.97v2.484h-1.22c-.437 0-.71.078-.82.234-.11.156-.164.422-.164.797v1.219h2.25L15.094 12z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}
