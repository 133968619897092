import * as React from 'react'

export default () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <circle fill="currentColor" cx="12" cy="12" r="12" />
        <path
          d="M7.194 9.04a.487.487 0 0 0-.16-.411L5.86 7.212V7h3.652l2.823 6.19L14.815 7h3.481v.212l-1.005.964a.295.295 0 0 0-.112.282v7.084a.294.294 0 0 0 .112.282l.982.964V17h-4.94v-.212l1.018-.987c.1-.1.1-.13.1-.282V9.793l-2.83 7.184h-.381L7.946 9.793v4.815a.664.664 0 0 0 .182.552l1.324 1.605v.212H5.7v-.212l1.323-1.605a.64.64 0 0 0 .17-.552V9.04z"
          fill="#fff"
        />
      </g>
    </svg>
  )
}
